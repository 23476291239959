// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = ['656 154',`
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="343px" height="93px" viewBox="0 0 343 93" enable-background="new 0 0 343 93" xml:space="preserve">
<g>
	<path fill="#E30613" d="M23.822,20.47c3.198-0.656,9.02-1.148,14.678-1.148c7.462,0,12.054,0.902,15.826,3.526
		c3.444,2.05,5.74,5.658,5.74,10.332c0,5.084-3.198,9.758-9.184,11.972v0.164c5.822,1.476,11.152,6.068,11.152,13.694
		c0,4.92-2.132,8.774-5.33,11.398c-3.936,3.444-10.414,5.166-20.5,5.166c-5.576,0-9.84-0.41-12.382-0.738V20.47z M33.826,42.282
		h5.166c6.97,0,10.906-3.28,10.906-7.954c0-5.166-3.936-7.544-10.332-7.544c-2.952,0-4.674,0.164-5.74,0.41V42.282z M33.826,67.702
		c1.312,0.164,3.034,0.246,5.33,0.246c6.478,0,12.218-2.46,12.218-9.266c0-6.396-5.576-9.02-12.546-9.02h-5.002V67.702z"/>
	<path fill="#E30613" d="M78.186,57.862c0.246,7.216,5.904,10.332,12.3,10.332c4.674,0,8.036-0.656,11.07-1.804l1.476,6.97
		c-3.444,1.394-8.2,2.46-13.94,2.46c-12.956,0-20.582-7.954-20.582-20.172c0-11.07,6.724-21.484,19.516-21.484
		c12.956,0,17.22,10.66,17.22,19.434c0,1.886-0.164,3.362-0.328,4.264H78.186z M95.734,50.81c0.082-3.69-1.558-9.758-8.282-9.758
		c-6.232,0-8.856,5.658-9.266,9.758H95.734z"/>
	<path fill="#E30613" d="M124.926,23.667v11.398h9.594v7.544h-9.594v17.63c0,4.838,1.312,7.38,5.166,7.38
		c1.722,0,3.034-0.246,3.936-0.492l0.164,7.708c-1.476,0.574-4.1,0.984-7.298,0.984c-3.69,0-6.806-1.23-8.692-3.28
		c-2.132-2.296-3.116-5.904-3.116-11.152V42.61h-5.74v-7.544h5.74v-9.02L124.926,23.667z"/>
	<path fill="#E30613" d="M164.614,75l-0.656-4.428h-0.246c-2.46,3.116-6.642,5.33-11.808,5.33c-8.036,0-12.546-5.822-12.546-11.89
		c0-10.086,8.938-15.17,23.698-15.088v-0.656c0-2.624-1.066-6.97-8.118-6.97c-3.936,0-8.036,1.23-10.742,2.952l-1.968-6.56
		c2.952-1.804,8.118-3.526,14.432-3.526c12.792,0,16.482,8.118,16.482,16.81v14.432c0,3.608,0.164,7.134,0.574,9.594H164.614z
		 M163.302,55.484c-7.134-0.164-13.94,1.394-13.94,7.462c0,3.936,2.542,5.74,5.74,5.74c4.018,0,6.97-2.624,7.872-5.494
		c0.246-0.738,0.328-1.558,0.328-2.214V55.484z"/>
	<path d="M184.211,20.47c3.772-0.656,9.43-1.148,15.252-1.148c7.955,0,13.367,1.312,17.057,4.428
		c3.034,2.542,4.756,6.396,4.756,11.07c0,7.134-4.838,11.972-10.004,13.858v0.246c3.936,1.476,6.314,5.33,7.708,10.66
		c1.722,6.888,3.198,13.284,4.347,15.416h-10.414c-0.82-1.64-2.133-6.15-3.69-13.038c-1.558-7.216-4.183-9.512-9.923-9.676h-5.084
		V75h-10.004V20.47z M194.215,44.906h5.986c6.807,0,11.07-3.608,11.07-9.102c0-6.068-4.264-8.856-10.824-8.856
		c-3.197,0-5.248,0.246-6.232,0.492V44.906z"/>
	<path d="M261.782,50.482h-20.828v16.236h23.288V75h-33.374V19.731h32.145v8.282h-22.059v14.268h20.828V50.482z"/>
	<path d="M273.098,20.47c4.428-0.738,10.086-1.148,16.072-1.148c10.332,0,17.466,2.132,22.55,6.396
		c5.33,4.346,8.61,10.906,8.61,20.336c0,9.84-3.362,17.22-8.61,21.976c-5.494,5.002-14.269,7.544-25.01,7.544
		c-5.904,0-10.332-0.328-13.612-0.738V20.47z M283.184,67.292c1.394,0.246,3.526,0.246,5.576,0.246
		c13.12,0.082,20.91-7.134,20.91-21.156c0.082-12.218-6.971-19.188-19.599-19.188c-3.197,0-5.494,0.246-6.888,0.574V67.292z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
`]
