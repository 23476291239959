import { authHeader } from '../_helpers';
import {API_URL} from '../api'
import router from "../router";

export const userService = {
    login,
    logout,
    getAll
};

function login(username, password, unitID) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, unitID })
    };
    localStorage.removeItem('user');
    localStorage.removeItem('ctypes');
    return fetch(`${API_URL}/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
              // login successful if there's a jwt token in the response
            if (user.data.session_hash) {
                user.token = user.data.session_hash;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('colors', user.data.colors);
                localStorage.setItem('filter', JSON.stringify([]));
                localStorage.setItem('pagecount', 20);
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('colors');
    router.push("/login");
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
