import {API_URL} from "../api";
import {authHeader} from "../_helpers";
import {userService} from "../_services";

import axios from 'axios'

export const dataService = {
    axiosFetch,
    axiosDelete,
    axiosPost,
    getCheckboxOptions,
    axiosFetchExternal,
};

function getCheckboxOptions(results) {
    var roleops = [];
    results.forEach(obj => {
        roleops.push({"value" : obj.id , "label" : obj.title});
    });
    return roleops;
}

function axiosDelete(url, selectId) {
    return axios.delete(API_URL + "/" + url, {
        credentials: 'include',
        data: {
            results: 10,
            ID: selectId,
        },
        headers: authHeader(),
    }).catch(function (error) {
        if (error.response.data.logout)
            userService.logout();
    }).then(results => {
        if (results.data.success === true) {
            return true;
        }
        else
            return results.data.message;
    });
}


function axiosPost(url, data) {
    return axios.put(API_URL + "/" + url, data, {
        credentials: 'include',
        headers: authHeader(),
    }).catch(function (error) {
        if (error.response.data.logout)
            userService.logout();
    }).then(results => {
        return results;
    });
}

function axiosFetch(url, ID = null) {
    let data = [];
    this.loading = true;
    if (ID)
        ID = "/" + ID; else ID = "";
    return axios.get(API_URL + "/" + url + ID, {
        credentials: 'include',
        data: {
            results: 10,
        },
        headers: authHeader(),
    }).catch(function (error) {
        if (error.response.data.logout)
            userService.logout();
    }).then(results => {
        data = results.data.data;
        this.loading = false;
        return data;
    });


}
function axiosFetchExternal(url, ID = null) {
    let data = [];
    this.loading = true;
    if (ID)
        ID = "/" + ID; else ID = "";
    return axios.get(url + ID, { 
    }).catch(function (error) {
        if (error.response.data.logout)
            userService.logout();
    }).then(results => {
        data = results;
        this.loading = false;
        return data;
    });


}
