import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import {store} from './_store'
import 'ant-design-vue/dist/antd.css'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

Vue.filter('startsWith', function (array, search, field) {
  if (!search.length) return array;

  return array.filter(function(element) {
    return element[field].toLowerCase().indexOf(search.toLowerCase()) === 0;
  })
})
Vue.filter('truncate', function (value, limit) {
  if(value)
  {if (value.length > limit) {
    value = value.substring(0, (limit - 3)) + '...';
  }
    }
  return value
})
import {Table} from 'ant-design-vue';

import FormItem from 'ant-design-vue'

Vue.use(FormItem);
Vue.use(Table);
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

import './override.css'; // variables to override above
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
